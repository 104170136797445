.ultraBigContainerMobileLandingHotels {
  height: calc(100vh - 100px);
  display: flex;
  align-items: flex-start;
  background-color: #FEFAF1;
  position: relative;
  padding-top: 100px;
}

.MobileLandingHotels {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.MobileLandingHotels .slick-slide {
  position: relative;
  text-align: center;
}

.MobileLandingHotels .slide img {
  width: 100%;
  height: auto;
  display: block;
}

.MobileLandingHotels .filter{
  display: flex;
  justify-content: space-between;
  width: 40%;
  color: black;
  margin-bottom: 10px;
  margin-left: 10px;
}

.MobileLandingHotels .filter button {
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  font-family: 'calsonpro';
  padding: 0px;
  margin: 0px;
  width: fit-content;
  color: black;
  font-size: 15px;
}

.MobileLandingHotels .filter button.active {
  border-bottom: 1px solid black;
  font-family: 'calsonproitalic';
}

.MobileLandingHotels .hotel-info h2{
    color: white;
    font-size: 12px;
    font-family: 'gustavo';
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    font-weight: 300;
    z-index: 20;
}

.MobileLandingHotels .hotel-info .hotel-address{
  color: white;
  font-size: 13px;
  font-family: 'calsonproitalic';
  letter-spacing: 0.02rem;
  font-weight: 100;
  font-style: italic;
  z-index: 20;
}

.ultraBigContainerMobileLandingHotels .travel{
  position: absolute;
  bottom: 105px;
  right: 15px;
  color: white;
  font-size: 12px;
  font-family: 'gustavo';
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  font-weight: 300;
  z-index: 20;
  margin: 0px;
}

.hotel-info {
  position: absolute;
  bottom: 84px;
  left: 15px;
  color: white;
  text-align: center;
  z-index: 20;
  opacity: 1;
  transition: opacity 500ms ease-in-out;
  text-align: left;
}

.hotel-info.visible {
  opacity: 1;
}

.hotel-info:not(.visible) {
  opacity: 0; /* Cela permet d'assurer que l'élément est caché par défaut, si nécessaire */
}

.hotel-name {
  font-size: 1.5rem;
  margin: 0;
}

.hotel-address {
  font-size: 1rem;
  margin: 0;
}

.slick-prev, .slick-next {
  color: #000; /* Change color of arrows if needed */
}

@media (max-width: 768px) {
  .ultraBigContainerMobileLandingHotels .slick-prev, .ultraBigContainerMobileLandingHotels .slick-next {
      display: none;
  }
}
